import { http } from "@/utils/http";
import { baseUrlApi } from "./utils";

/** 文档管理-添加产品属性 */
export const add_son_all = data => {
  return http.request("post", baseUrlApi("add_son_all"), { data });
};

/** 文档管理-获取所有品牌 */
export const get_brand_all = data => {
  return http.request("get", baseUrlApi("get_brand_all"), { data });
};

/** 文档管理-获取所有型号 */
export const get_model_all = data => {
  return http.request("get", baseUrlApi("get_model_all"), { data });
};

/** 文档管理-获取所有颜色 */
export const get_colour_all = data => {
  return http.request("get", baseUrlApi("get_colour_all"), { data });
};

/** 文档管理-获取所有配置 */
export const get_specifications_all = data => {
  return http.request("get", baseUrlApi("get_specifications_all"), { data });
};

/** 文档管理-获取所有类别 */
export const get_ProductCategory_all = data => {
  return http.request("get", baseUrlApi("get_ProductCategory_all"), { data });
};

/** 文档管理-创建产品 */
export const add_product = data => {
  return http.request("post", baseUrlApi("add_product"), { data });
};

/** 文档管理-获取产品列表 */
export const get_product_list = data => {
  return http.request("post", baseUrlApi("get_product_list"), { data });
};

/** 文档管理-修改产品状态 */
export const product_updata_status = data => {
  return http.request("post", baseUrlApi("product_updata_status"), { data });
};

/** 文档管理-更新产品图片 */
export const updata_product_image = data => {
  return http.request("post", baseUrlApi("updata_product_image"), { data });
};

/** 文档管理-图片上传 */
export const post_picture = data => {
  return http.request(
    "post",
    baseUrlApi("post_picture"),
    { data },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

/**获取图片列表 */
export const get_picture_list = data => {
  return http.request("post", baseUrlApi("get_picture_list"), { data });
};

/**删除图片 */
export const delete_picture = data => {
  return http.request("post", baseUrlApi("delete_picture"), { data });
};

/** 文档管理-新增供货商 */
export const add_supplier = data => {
  return http.request("post", baseUrlApi("add_supplier"), { data });
};
/** 文档管理-修改供货商 */
export const update_supplier = data => {
  return http.request("post", baseUrlApi("update_supplier"), { data });
};
/** 文档管理-获取供货商列表 */
export const get_supplier_list = data => {
  return http.request("post", baseUrlApi("get_supplier_list"), { data });
};

/**更新供货商状态 */
export const update_supplier_status = data => {
  return http.request("post", baseUrlApi("update_supplier_status"), { data });
};

/** 文档管理-获取产品 */
export const get_product_all = data => {
  return http.request("post", baseUrlApi("get_product_all"), { data });
};
/** 文档管理-修改供货商 */
export const updata_product = data => {
  return http.request("post", baseUrlApi("updata_product"), { data });
};

/** 添加业务档案 */
export const add_business = data => {
  return http.request("post", baseUrlApi("add_business"), { data });
};

/** 获取业务档案列表 */
export const get_business_list = data => {
  return http.request("post", baseUrlApi("get_business_list"), { data });
};

/** 修改业务档案 */
export const update_business = data => {
  return http.request("post", baseUrlApi("update_business"), { data });
};

/** 删除业务档案 */
export const delete_business = data => {
  return http.request("post", baseUrlApi("delete_business"), { data });
};

/** 添加往来单位 */
export const add_Contactunits = data => {
  return http.request("post", baseUrlApi("add_Contactunits"), { data });
};

/** 获取往来单位列表 */
export const get_Contactunits_list = data => {
  return http.request("post", baseUrlApi("get_Contactunits_list"), { data });
};

/** 修改往来单位 */
export const update_Contactunits = data => {
  return http.request("post", baseUrlApi("update_Contactunits"), { data });
};

/** 删除往来单位 */
export const delete_Contactunits = data => {
  return http.request("post", baseUrlApi("delete_Contactunits"), { data });
};

/** 添加收款项 */
export const add_collection = data => {
  return http.request("post", baseUrlApi("add_collection"), { data });
};

/** 获取收款项列表 */
export const get_collection_list = data => {
  return http.request("post", baseUrlApi("get_collection_list"), { data });
};

/** 修改收款项 */
export const update_collection = data => {
  return http.request("post", baseUrlApi("update_collection"), { data });
};

/** 删除收款项 */
export const delete_collection = data => {
  return http.request("post", baseUrlApi("delete_collection"), { data });
};

/** 添加工资项目 */
export const add_Salaryitems = data => {
  return http.request("post", baseUrlApi("add_Salaryitems"), { data });
};

/** 获取工资项目列表 */
export const get_Salaryitems_list = data => {
  return http.request("post", baseUrlApi("get_Salaryitems_list"), { data });
};

/** 修改工资项目 */
export const update_Salaryitems = data => {
  return http.request("post", baseUrlApi("update_Salaryitems"), { data });
};

/** 删除工资项目 */
export const delete_Salaryitems = data => {
  return http.request("post", baseUrlApi("delete_Salaryitems"), { data });
};

/** 添加收款信息 */
export const add_SalaryPlan = data => {
  return http.request("post", baseUrlApi("add_SalaryPlan"), { data });
};

/** 获取收款信息列表 */
export const get_SalaryPlan_list = data => {
  return http.request("post", baseUrlApi("get_SalaryPlan_list"), { data });
};

/** 添加激励档案 */
export const add_Incentivefile = data => {
  return http.request("post", baseUrlApi("add_Incentivefile"), { data });
};

/** 获取激励档案列表 */
export const get_Incentivefile_list = data => {
  return http.request("post", baseUrlApi("get_Incentivefile_list"), { data });
};

/** 更新激励档案 */
export const update_Incentivefile = data => {
  return http.request("post", baseUrlApi("update_Incentivefile"), { data });
};

/** 删除激励档案 */
export const delete_Incentivefile = data => {
  return http.request("post", baseUrlApi("delete_Incentivefile"), { data });
};

/*获取往来单位明细表 */
export const get_Contactunits_detail = data => {
  return http.request("post", baseUrlApi("get_Contactunits_detail"), { data });
};
