import { http } from "@/utils/http";
import { baseUrlApi } from "./utils";

/*添加仓库信息*/
export const add_WarehouseInformation = data => {
  return http.request("post", baseUrlApi("add_WarehouseInformation"), { data });
};

/*获取仓库信息*/
export const get_WarehouseInformation_list = data => {
  return http.request("post", baseUrlApi("get_WarehouseInformation_list"), {
    data
  });
};

/**删除仓库数据*/
export const delete_WarehouseInformation = data => {
  return http.request("post", baseUrlApi("delete_WarehouseInformation"), {
    data
  });
};

/**更新仓库数据*/
export const update_WarehouseInformation = data => {
  return http.request("post", baseUrlApi("update_WarehouseInformation"), {
    data
  });
};

/**查询仓库信息*/
export const get_mktResStoreId = data => {
  return http.request("post", baseUrlApi("get_mktResStoreId"), {
    data
  });
};

//*串码查询*/
export const QueryImie = data => {
  return http.request("post", baseUrlApi("QueryImie"), {
    data
  });
};

/**提交调拨 */
export const getBatch = data => {
  return http.request("post", baseUrlApi("getBatch"), {
    data
  });
};

/**第一次校验 */
export const beforeAllocateResourceInst = data => {
  return http.request("post", baseUrlApi("beforeAllocateResourceInst"), {
    data
  });
};

//*第二次校验*/
export const retailerAllowCheck = data => {
  return http.request("post", baseUrlApi("retailerAllowCheck"), {
    data
  });
};

/**确认调拨 */
export const allocateResourceInst = data => {
  return http.request("post", baseUrlApi("allocateResourceInst"), {
    data
  });
};

/**查看调拨列表 */
export const listResourceRequest = data => {
  return http.request("post", baseUrlApi("listResourceRequest"), {
    data
  });
};

/* 接收调拨*/
export const post_allocateAddNbr = data => {
  return http.request("post", baseUrlApi("post_allocateAddNbr"), {
    data
  });
};

/**获取易购网补贴信息 */
export const get_Cost_support_list = data => {
  return http.request("post", baseUrlApi("get_Cost_support_list"), {
    data
  });
};

//**一键获取易购网前置信息 */
export const yijian_Cost_support = data => {
  return http.request("post", baseUrlApi("yijian_Cost_support"), {
    data
  });
};

//**重置状态 */
export const set_yigowebzt = () => {
  return http.request("get", baseUrlApi("set_yigowebzt"));
};

/**获取状态 */
export const get_yigowebzt = () => {
  return http.request("get", baseUrlApi("get_yigowebzt"));
};

//**添加前置补贴信息 */
export const add_Cost_support = data => {
  return http.request("post", baseUrlApi("add_Cost_support"), {
    data
  });
};

/***同步补贴状态 */
export const tongbu_Cost_support = () => {
  return http.request("get", baseUrlApi("tongbu_Cost_support"));
};
