import { http } from "@/utils/http";
import { baseUrlApi } from "./utils";

/** 获取系统管理-用户管理列表 */
export const getUserList = data => {
  return http.request("post", baseUrlApi("getUserList"), { data });
};

/** 获取系统管理-用户管理列表 */
export const getUser_id_name = data => {
  return http.request("get", baseUrlApi("getUser_id_name"), { data });
};

/** 获取系统管理-添加用户 */
export const AddUserList = data => {
  return http.request("post", baseUrlApi("AddUserList"), { data });
};

/** 获取系统管理-更新用户 */
export const updatePassword = data => {
  return http.request("post", baseUrlApi("updatePassword"), { data });
};

/** 获取系统管理-更新用户 */
export const updateUser = data => {
  return http.request("post", baseUrlApi("updateUser"), { data });
};

/** 删除系统管理-用户管理 */
export const deleteUser = data => {
  return http.request("post", baseUrlApi("deleteUser"), { data });
};

/** 设置用户角色 */
export const setUserRole = data => {
  return http.request("post", baseUrlApi("setUserRole"), { data });
};

/** 系统管理-用户管理-获取所有角色列表 */
export const getAllRoleList = () => {
  return http.request("get", baseUrlApi("getAllRoleList"));
};

/** 系统管理-用户管理-根据userId，获取对应角色id列表（userId：用户id） */
export const getRoleIds = data => {
  return http.request("post", baseUrlApi("list-role-ids"), { data });
};

/** 获取系统管理-角色管理列表 */
export const getRoleList = data => {
  return http.request("post", baseUrlApi("getRoleList"), { data });
};

/** 添加系统管理-角色管理列表 */
export const AddRole = data => {
  return http.request("post", baseUrlApi("AddRole"), { data });
};

/** 更新系统管理-角色管理列表 */
export const updateRole = data => {
  return http.request("post", baseUrlApi("updateRole"), { data });
};

/** 删除系统管理-角色管理列表 */
export const deleteRole = data => {
  return http.request("post", baseUrlApi("deleteRole"), { data });
};
/** 获取系统管理-菜单管理列表 */
export const getMenuList = data => {
  return http.request("post", baseUrlApi("getMenuList"), { data });
};

/** 添加系统管理-菜单管理列表 */
export const addMenuList = data => {
  return http.request("post", baseUrlApi("addMenuList"), { data });
};

/** 添加系统管理-菜单管理列表 */
export const updateMenu = data => {
  return http.request("post", baseUrlApi("updateMenu"), { data });
};
/** 删除系统管理-菜单管理列表 */
export const deleteMenu = data => {
  return http.request("post", baseUrlApi("deleteMenu"), { data });
};

/** 获取系统管理-部门管理列表 */
export const getDeptList = data => {
  return http.request("post", baseUrlApi("getDeptList"), { data });
};
/** 新增系统管理-部门管理 */
export const addDept = data => {
  return http.request("post", baseUrlApi("addDept"), { data });
};
/** 修改系统管理-部门管理 */
export const updateDept = data => {
  return http.request("post", baseUrlApi("updateDept"), { data });
};
/** 修改系统管理-部门管理 */
export const deleteDept = data => {
  return http.request("post", baseUrlApi("deleteDept"), { data });
};

/** 获取系统监控-在线用户列表 */
export const getOnlineLogsList = data => {
  return http.request("post", "/online-logs", { data });
};

/** 获取系统监控-登录日志列表 */
export const getLoginLogsList = data => {
  return http.request("post", "/login-logs", { data });
};

/** 获取系统监控-操作日志列表 */
export const getOperationLogsList = data => {
  return http.request("post", "/operation-logs", { data });
};

/** 获取系统监控-系统日志列表 */
export const getSystemLogsList = data => {
  return http.request("post", "/system-logs", { data });
};

/** 获取系统监控-系统日志-根据 id 查日志详情 */
export const getSystemLogsDetail = data => {
  return http.request("post", "/system-logs-detail", { data });
};

/** 获取角色管理-权限-菜单权限 */
export const getRoleMenu = data => {
  return http.request("post", baseUrlApi("role-menu"), { data });
};

/** 获取角色管理-权限-菜单权限 */
export const setRoleMenu = data => {
  return http.request("post", baseUrlApi("setRoleMenu"), { data });
};
/** 获取角色管理-权限-菜单权限-根据角色 id 查对应菜单 */
export const getRoleMenuIds = data => {
  return http.request("post", baseUrlApi("role-menu-ids"), { data });
};

/** 修改锁屏密码 */
export const update_Lockscreenpassword = data => {
  return http.request("post", baseUrlApi("update_Lockscreenpassword"), {
    data
  });
};

/** 修改锁屏状态 */
export const updateis_lock = data => {
  return http.request("post", baseUrlApi("updateis_lock"), { data });
};

/** 校验锁屏密码 */
export const check_Lockscreenpassword = data => {
  return http.request("post", baseUrlApi("check_Lockscreenpassword"), {
    data
  });
};

/** 获取锁屏状态 */
export const get_is_lock = data => {
  return http.request("post", baseUrlApi("get_is_lock"), { data });
};

/*添加通知*/
export const add_notice = data => {
  return http.request("post", baseUrlApi("add_notice"), { data });
};

/**更新通知代办信息 */
export const edit_notice = data => {
  return http.request("post", baseUrlApi("edit_notice"), { data });
};

/*获取通知列表 */
export const get_notice_list = data => {
  return http.request("post", baseUrlApi("get_notice_list"), { data });
};

/*删除通知 */
export const del_notice = data => {
  return http.request("post", baseUrlApi("del_notice"), { data });
};

/* 获取通知对象列表 */
export const get_notice_user_id = data => {
  return http.request("post", baseUrlApi("get_notice_user_id"), { data });
};

/* 设置通知对象 */
export const set_notice_user_id = data => {
  return http.request("post", baseUrlApi("set_notice_user_id"), { data });
};

/* 获取通知列表 */
export const get_user_notice = () => {
  return http.request("get", baseUrlApi("get_user_notice"));
};

/* 获取通知列表 */
export const set_user_notice_status = data => {
  return http.request("post", baseUrlApi("set_user_notice_status"), { data });
};
