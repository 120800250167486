<template>
  <el-dialog
    v-model="showDrawer"
    fullscreen
    :destroy-on-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="false"
  >
    <div class="dialog-content">
      <img src="./assets/A.png" alt="" />
      <el-input
        v-model="input"
        style="max-width: 600px"
        placeholder="请输入锁屏密码"
        @keyup.enter="Lockscreen"
      />
      <el-button type="primary" style="margin-left: 5px" @click="Lockscreen"
        >提交</el-button
      >
    </div>
  </el-dialog>

  <el-config-provider :locale="currentLocale">
    <!-- <keep-alive> -->
    <router-view />
    <!-- </keep-alive> -->
    <ReDialog />
  </el-config-provider>
  <div id="container" />
</template>

<script>
import { defineComponent, onMounted, ref, nextTick } from "vue";
import { ElConfigProvider, ElMessage } from "element-plus";
import { ReDialog } from "@/components/ReDialog";
import en from "element-plus/es/locale/lang/en";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import {
  updateis_lock,
  check_Lockscreenpassword,
  get_is_lock
} from "@/api/system";
import { init_app_data } from "./views/hooks";
import { storageLocal, useWatermark } from "@pureadmin/utils";
export default defineComponent({
  name: "app",
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
    ReDialog
  },
  setup() {
    const employ = ref(0);
    const showDrawer = ref(false);
    const input = ref("");
    const handleMouseMove = event => {
      employ.value = 0;
    };
    const updateMouseStatus = () => {
      if (!showDrawer.value) {
        employ.value += 1;
        if (employ.value >= 3600) {
          updateis_lock({ id: storageLocal().getItem("userid"), is_lock: 1 });
          showDrawer.value = true;
        }
      }
    };

    const { setWatermark } = useWatermark();

    onMounted(() => {
      init_app_data();
      document.addEventListener("mousemove", handleMouseMove);
      setInterval(updateMouseStatus, 100);
      get_is_lock({ id: storageLocal().getItem("userid") }).then(res => {
        showDrawer.value = res.data === 1;
      });
      nextTick(() => {
        setWatermark(storageLocal().getItem("user-info").username, {
          forever: true,
          width: 400,
          height: 400,
          rotate: 210
        });
        console.log(setWatermark);
      });
    });

    const Lockscreen = () => {
      check_Lockscreenpassword({
        id: storageLocal().getItem("userid"),
        Lockscreenpassword: input.value
      })
        .then(res => {
          console.log(res);
          if (res.success) {
            showDrawer.value = false;
            updateis_lock({ id: storageLocal().getItem("userid"), is_lock: 0 });
            input.value = "";
            ElMessage.success("解锁成功");
          } else {
            ElMessage.error("锁屏密码错误");
            input.value = "";
          }
        })
        .catch(err => {
          console.log(err);
        });
    };
    return {
      employ,
      showDrawer,
      input,
      Lockscreen
    };
  },
  computed: {
    currentLocale() {
      return this.$storage.locale?.locale === "zh" ? zhCn : en;
    }
  }
});
</script>
<style scoped>
.dialog-content {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px;
}
</style>
