import { $t } from "@/plugins/i18n";
const Layout = () => import("@/layout/index.vue");

export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/index.vue"),
    meta: {
      title: $t("menus.pureLogin"),
      showLink: false,
      rank: 101
    }
  },
  {
    path: "/redirect",
    component: Layout,
    meta: {
      title: $t("status.pureLoad"),
      showLink: false,
      rank: 102
    },
    children: [
      {
        path: "/redirect/:path(.*)",
        name: "Redirect",
        component: () => import("@/layout/redirect.vue")
      }
    ]
  },
  {
    path: "/mobile",
    name: "Home1",
    component: () => import("@/layout/index2.vue"),
    meta: {
      title: $t("menus.pureHome"),
      showLink: false,
      rank: 103
    },
    redirect: "/mobile/move/home",
    children: [
      {
        path: "/mobile/move/home",
        name: "MobileHome",
        component: () => import("@/views/move/home.vue")
      },
      {
        path: "/mobile/move/AttendanceManagement",
        name: "AttendanceManagement",
        component: () => import("@/views/move/AttendanceManagement.vue")
      },
      {
        path: "/move/moveSale",
        name: "moveSale",
        component: () => import("@/views/move/moveSale.vue")
      },
      {
        path: "/mobile/move/PersonalCenter",
        name: "PersonalCenter1",
        component: () => import("@/views/move/PersonalCenter.vue")
      },
      {
        path: "/mobile/move/OrderList",
        name: "OrderList",
        component: () => import("@/views/move/OrderList.vue")
      },
      {
        path: "/mobile/move/QueryBuilder",
        name: "QueryBuilder",
        component: () => import("@/views/move/QueryBuilder.vue")
      },
      {
        path: "/mobile/move/show/CustomerDashboard",
        name: "CustomerDashboard",
        component: () => import("@/views/move/show/CustomerDashboard.vue")
      },
      {
        path: "/mobile/move/inventory_inspect",
        name: "inventory_inspect",
        component: () => import("@/views/move/inventoryinspect.vue")
      },
      {
        path: "/mobile/move/moveInventoryDetails",
        name: "moveInventoryDetails",
        component: () => import("@/views/move/moveInventoryDetails.vue")
      },
      {
        path: "/mobile/move/Employeereimbursementlist",
        name: "Employeereimbursementlist",
        component: () => import("@/views/move/Employeereimbursementlist.vue")
      },
      {
        path: "/mobile/move/Telecommunication",
        name: "Telecommunication",
        component: () => import("@/views/move/Telecommunication.vue")
      }
    ]
  }
];
