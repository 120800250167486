import { http } from "@/utils/http";
import { baseUrlApi } from "./utils";

/** 新增查询组 */
export const add_QueryBuilder = data => {
  return http.request("post", baseUrlApi("add_QueryBuilder"), { data });
};

/** 删除查询组 */
export const del_QueryBuilder = data => {
  return http.request("post", baseUrlApi("del_QueryBuilder"), { data });
};

/** 获取查询组列表 */
export const get_QueryBuilder_list = data => {
  return http.request("post", baseUrlApi("get_QueryBuilder_list"), { data });
};

/* 更新查询组*/
export const update_QueryBuilder = data => {
  return http.request("post", baseUrlApi("update_QueryBuilder"), { data });
};

/** 筛选客户 */
export const get_customer_list = data => {
  return http.request("post", baseUrlApi("get_customer_list"), { data });
};

export const get_sceneNameOne = () => {
  return http.request("get", baseUrlApi("get_sceneNameOne"));
};

export const get_strategyName = () => {
  return http.request("get", baseUrlApi("get_strategyName"));
};

export const get_customerDiagnostics_strategyName = () => {
  return http.request(
    "get",
    baseUrlApi("get_customerDiagnostics_strategyName")
  );
};

export const add_customer_count = data => {
  return http.request("post", baseUrlApi("add_customer_count"), { data });
};
