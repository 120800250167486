import { $t } from "@/plugins/i18n";
const Layout = () => import("@/layout/index.vue");

export default {
  path: "/",
  name: "Home",
  component: Layout,
  redirect: "/welcome",
  meta: {
    icon: "ep:home-filled",
    showLink: true,
    title: $t("menus.pureHome"),
    rank: 0
  },
  children: [
    {
      path: "/welcome",
      name: "Welcome",
      component: () => import("@/views/welcome/index.vue"),
      meta: {
        icon: "ep:home-filled",
        title: $t("menus.pureHome"),
        // showLink: VITE_HIDE_HOME === "true" ? false : true
        showLink: true
      }
    },
    {
      path: "/move/PersonalCenter",
      name: "PersonalCenter",
      component: () => import("@/views/move/PersonalCenter.vue"),
      meta: {
        icon: "ep:home-filled",
        title: "个人中心",
        // showLink: VITE_HIDE_HOME === "true" ? false : true
        showLink: false
      }
    }
  ]
};
