import { defineStore } from "pinia";
import {
  store,
  router,
  resetRouter,
  routerArrays,
  storageLocal
} from "../utils";
import { getLogin, refreshTokenApi } from "@/api/user";
import { useMultiTagsStoreHook } from "./multiTags";
import { setToken, removeToken, userKey } from "@/utils/auth";

// import { setToken } from "@/utils/auth";
// import { useWebSocket } from "@vueuse/core";
// const { status, data, send, close } = useWebSocket("ws://127.0.0.1:8000/ws");
// const xunhuan = () => {
//   console.log("发送请求");
//   console.log(status.value);
//   if (status.value == "CLOSED") {
//     console.log("断开链接");
//     close();
//   } else {
//     send(storageLocal().getItem("user-info").refreshToken);
//     let dic = JSON.parse(data.value);
//     setToken(dic.data);
//     console.log("设置新cookie");
//   }
// };
// setInterval(xunhuan, 20000);

export const useUserStore = defineStore({
  id: "pure-user",
  state: () => ({
    userid: storageLocal().getItem(userKey)?.userid ?? "",
    // 头像
    avatar: storageLocal().getItem(userKey)?.avatar ?? "",
    // 用户名
    username: storageLocal().getItem(userKey)?.username ?? "",
    // 昵称
    nickname: storageLocal().getItem(userKey)?.nickname ?? "",
    // 页面级别权限
    roles: storageLocal().getItem(userKey)?.roles ?? [],
    department: storageLocal().getItem(userKey)?.department ?? "",
    // 是否勾选了登录页的免登录
    isRemembered: false,
    // 登录页的免登录存储几天，默认7天
    loginDay: 7,
    is_lock: 0,
    //操作门店
    deps: storageLocal().getItem(userKey)?.deps ?? [],
    depname: storageLocal().getItem(userKey)?.depname ?? ""
  }),
  actions: {
    /** 锁屏状态 */
    SET_is_lock(lock) {
      this.is_lock = lock;
      storageLocal().setItem("is_lock", lock);
    },
    /** 存储用户ID */
    SET_USERID(userid) {
      this.userid = userid;
      storageLocal().setItem("userid", userid);
    },
    /** 存储头像 */
    SET_AVATAR(avatar) {
      this.avatar = avatar;
      storageLocal().setItem("avatar", avatar);
    },
    /** 存储用户名 */
    SET_USERNAME(username) {
      this.username = username;
      storageLocal().setItem("username", username);
    },
    /** 存储昵称 */
    SET_NICKNAME(nickname) {
      this.nickname = nickname;
      storageLocal().setItem("nickname", nickname);
    },
    /** 存储角色 */
    SET_ROLES(roles) {
      this.roles = roles;
      storageLocal().setItem("roles", roles);
    },
    /** 存储是否勾选了登录页的免登录 */
    SET_ISREMEMBERED(bool) {
      this.isRemembered = bool;
      storageLocal().setItem("isRemembered", bool);
    },
    /** 设置登录页的免登录存储几天 */
    SET_LOGINDAY(value) {
      this.loginDay = Number(value);
      storageLocal().setItem("loginDay", value);
    },
    SET_department(value) {
      this.department = Number(value);
      storageLocal().setItem("department", value);
    },
    SET_deps(value) {
      this.deps = value;
      storageLocal().setItem("deps", value);
    },
    SET_depname(value) {
      this.depname = value;
      storageLocal().setItem("depname", value);
    },
    /** 登入 */
    async loginByUsername(data) {
      return new Promise((resolve, reject) => {
        getLogin(data)
          .then(data => {
            if (data?.success) setToken(data.data);
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /** 前端登出（不调用接口） */
    logOut() {
      this.username = "";
      this.roles = [];
      removeToken();
      useMultiTagsStoreHook().handleTags("equal", [...routerArrays]);
      resetRouter();
      router.push("/login");
    },
    /** 刷新`token` */
    async handRefreshToken(data) {
      return new Promise((resolve, reject) => {
        refreshTokenApi(data)
          .then(data => {
            if (data) {
              if (data?.success) {
                setToken(data.data);
                resolve(data);
              } else {
                this.username = "";
                this.roles = [];
                removeToken();
                useMultiTagsStoreHook().handleTags("equal", [...routerArrays]);
                resetRouter();
                router.push("/login");
                location.reload();
                reject(new Error("Refresh token failed"));
              }
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
});

export function useUserStoreHook() {
  return useUserStore(store);
}
